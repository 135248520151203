.subscription-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 5rem;
  }

 .subscription-header {
  padding-top: 12vh;
    display: flex;
    color: aliceblue;
    gap:5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    font-style: italic;
  }

  .subscription-card {
    width: 250px;
    padding: 20px;
    border-radius: 1rem;
    background-color: var(--darkGrey);
    box-shadow: 0 2px 4px rgba(0, 119, 255, 0.6);
    color: white;
    text-align: center;
    transition: 0.4s;
  }
  
  .subscription-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .subscription-price {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .subscription-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .subscription-features li {
    margin-bottom: 5px;
  }
  
  .subscribe-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: var(--blue);
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    align-items: end;
    transition: 0.3s;
  }
  
  .subscribe-button:hover {
    background-color: #0257a7;;
  }
  
  .subscription-card:hover{
    background: rgb(63, 63, 63);
    scale:1.05;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    .subscription-card {
      width: 100%;
      max-width: 400px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .subscription-card {
      width: 300px;
      
    }
  }