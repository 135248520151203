.schedule{
  padding-top: 12vh;
  min-height: 100vh;
}

.schedule>span{
  display: flex;
  gap:5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  font-style: italic;
  margin-bottom: 5rem;
}
.table-container {
  width: 80vh;
  margin: 0 auto;
  border-radius: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(70, 77, 83, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  border-radius: 1rem;
}

th,
td {
  padding: 20px;
  text-align: center;
  /* -webkit-user-modify: read-write-plaintext-only; */
  -webkit-user-modify: read-only;

  content :false;
}

th {
  background-color: #f5f5f5;
  background-color: rgba(0, 119, 230, 0.9);
  margin: 1rem 1rem;
  font-weight: bold;
}

tr{
  text-align: center;
  align-items: baseline;
  justify-content: center;
  
}

tr:nth-child(even) {
  background-color: rgba(101, 101, 101, 0.9);
}
  
tr:hover {
  background-color: rgba(101, 101, 101, 0.5);
  transition: background-color 0.3s ease;
}
  
.schedule-footer>img{
  height: 2.5rem;
  width: 2.5rem;
}

.schedule-footer{
  margin-top: 1rem;
  color: aliceblue;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.schedule-footer-text{
    font-size: 28px;
} 

@media screen and (max-width: 900px) {
  .table-container {
    width: 90%;
    margin: 0 auto;
    border-radius: 1rem;
  }
}