
.header {
    display: flex;
    align-items: center;
    padding: 20px;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-image: 
    linear-gradient(90deg,  rgba(2,0,36,0.9)0%, rgba(0, 0, 0,0.9) 100%);
  }
  .header h3{
    padding-right: 50px;
  }
  .logo {
    width: 100px;
  }
  
  .menu-toggle {
    display: none;
    cursor: pointer;
    padding: 10px;
  }
  
  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .header-menu {
    display: flex;
    list-style: none;
    gap:3rem;
    margin: auto auto;
  }
  
  .header-menu a {
    height: 2rem;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
    transition: 0.4s;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .header-menu a:hover {
    scale:1.2;
    cursor: pointer;
    color: var(--blue);
  }
  
  .show {
    display: flex ;

  }
  
  @media (max-width: 768px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menu-toggle {
      display: block;
      z-index: 50;
    }
    
    .header-menu {
      display: none;
     
    }
    .show {
        display: flex ;
        background: linear-gradient(90deg,  rgba(2,0,36,0.9)0%, rgba(0, 0, 0,0.9) 100%);

        flex-direction: column;
       
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        text-align: center;
      }
  
    .header-menu li {
      margin: 5px 0;
    }
    .show :nth-last-child(1){
        padding-bottom:20px ;
    }
  
    .show {
      animation: fadeSlideDown 0.3s ease-in-out forwards;
    }
  }
  
  @keyframes fadeSlideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  