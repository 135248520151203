/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --blue:#0077e6;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor:  #3c3f45;
}

.App {
  /* mix-blend-mode: overlay;
  background-color: var(--appColor); */
  display: flex;
  flex-direction: column;
  gap: rem;
  overflow: hidden;
  background-image: linear-gradient(90deg,  rgba(2,0,36,1)0%, rgba(0, 0, 0) 100%);

}
::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color:transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border:4px solid transparent ;
  transition: 0.3s;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
}
.btn:hover{
    cursor: pointer;
    ;
}