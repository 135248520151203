.hero-section{
    padding-top: 15vh;
    min-height: 90vh;
}
.hero{
    display: flex;
    justify-content: space-between;
    margin-top:-50px ;
    /* background-image:linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(2,0,36, 0.5)),
    url('../../assets/hero_background.jpg'); */
}

.left-h{
    padding: 2rem ;
    padding-top: 1rem;
    flex:3;
    display:flex;
    gap:2rem;
    flex-direction: column; 
    z-index: 5;
    height: 100%;
}

.right-h{
    flex:1;
    position: relative;
}

.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    width: 300px;
    padding: 20px 13px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.hero-plante{  
    width: 100%;
    height: 100%;
}
.the-best-ad>div{
    position: absolute ;
    background-color: var(--blue);
    width: 5.4rem;
    height: 80%;
    left :8px;
    border-radius: 3rem;
    z-index: 1;
    justify-content: center;
    align-items: center;
    animation: slidePlane 5s linear infinite;

}
.the-best-ad>span{
    z-index: 2;
}

.hero-text{
    display:flex;
    flex-direction: column;
    gap:1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color:white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: end;
    gap:5rem;
    margin: auto auto;
    text-align: center;
    height: 30vh;
}
.figures>div{
    display:flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color:var(--gray);
    text-transform: none;
}



.hero-image{
    position: absolute;
    top:8rem;
    width:45rem;
    left:50%;
    z-index: 1;
}

.home-page-img{
    position: absolute;
    top:8rem;
    width:45rem;
    height: 100%;
    left:50%;
    z-index: 0;
    background-image:linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(2,0,36, 0.5)),
     url('../../assets/hero_image4.png');  
}

.hero-image-back{
    position: absolute;
    top:4rem;
    right: 20rem;
    z-index :0;
    width: 16rem;
}

.herooo {
    justify-content: end;
    width: 100%;

}
.herooo img{
    width: 7rem;
    left: 10%;
    position: absolute;
}


@media screen and (max-width: 1700px) {
    .hero-image{
        z-index: -1;;
    }
}
@media screen and (max-width: 1008px) {
    .hero-section{
        background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(2,0,36, 0.5)),
        url('../../assets/hero_image4.png');  
        background-repeat: no-repeat;
    }
    .right-h{
        display: none;
    }
    .hero-image{
        display: none;
    }
    .figures{
        align-items: center;
        width: 90%;
    }
    .hero-text{
        font-size: 3.5rem;
    }
}


@keyframes slidePlane {
    0% {
      transform: translateX(0%);
    }
   
    100% {
      transform: translateX(260%);
    }
}