.reasons-section{
    padding-top: 12vh;
    min-height: 100vh;
    margin: auto auto;
    align-items: center;
}

.reasons{
    padding: 0 2rem ;
    display: flex;
    grid-template: 2rem;
    gap:2rem;
    margin: auto auto;
    align-items: center;
    margin-top: 15vh;
}

.left-r{
    flex :1 1 ;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr;
    gap:2rem;
}

.left-r>img{
    object-fit: cover;
    border-radius: 1rem;
    transition: 0.4s;
}

.left-r>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 34rem; 
    margin: auto auto;
}
.left-r>:nth-child(2){
    width: auto;
    grid-row: 1/3;
    grid-column: 2/3;
    height: 260px;
    width: 30rem; 
}
.left-r>:nth-child(3){
    width: auto;
    grid-row: 2/3;
    grid-column: 2/3;
    height: 260px;
    width: 30rem;
}
.left-r>:nth-child(4){
    width: 12rem;
    grid-row: 1/3;
    grid-column: 3/3;
    height: 34rem; 
    margin: auto auto;
}

.left-r>img:hover{
    scale: 1.05;
    box-shadow:-3px 3px 1px var(--blue);
    border: 1px sold white;
}

.right-r{
    display: flex;
    flex: 1 1;
    text-transform: uppercase;
    gap:1rem;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color:var(--blue);
}

.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap:2rem;
}
.details-r>div{
    display: flex;
   font-size: 1rem;
    gap:1rem;
}
.details-r>div>img{
    width: 2.5rem;
    height: 2.5rem;
    
}
.details-r b{
    font-size: 22px;
    color: #fff;
}
.details-r span{
    font-size: 16px;
    color: #c7c7c7;
}
.reasons-header{
    display: flex;
    color: aliceblue;
    gap:5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    font-style: italic;
    margin-bottom: 3rem;
}

@media screen and (max-width: 1008px) {
    /*  */
    .left-r{
        flex :1 1 ;
        grid-template-columns: repeat(2,1fr);
        align-items: center;
    }
    .left-r>:nth-child(1){
        margin-top: 0px;
    }
    .left-r>:nth-child(2){
        grid-row: 2/2;
        grid-column: 1/2;
        display: none;
    }
    .left-r>:nth-child(3){
        grid-row: 3/3;
        grid-column: 1/2 ;
        width: auto;
        display: none;
    }
    .left-r>:nth-child(4){
        grid-row: 1/2;
        grid-column: 2/2;
        margin-top: 0px;
    }
}

@media screen and (max-width: 1508px) {
    .reasons{
        display: flex;
        flex-direction: column;
        margin-top: 5vh;
    }
    
}
@media screen and (max-width: 500px) {
    .left-r>:nth-child(1){
        width: 100%;
    }
    
    .left-r>:nth-child(4){
        width: 100%;
    }
    .reasons{

        gap:2rem;
    }
}