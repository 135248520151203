.programs{
  display: flex;
  flex-direction: column;
  gap:2rem;
  padding: 2rem;
  padding-top: 12vh;
  min-height: 100vh;
}

.programs-header{
  display: flex;
  color: aliceblue;
  gap:5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  font-style: italic;
  margin-bottom: 3rem;
}

.program-categories{
    display: flex;
    gap :1rem;
    justify-content: space-around;
}
.category{
  display: flex;
  color: white;
  flex-direction: column;
  background-color: var(--darkGrey);
  padding: 2rem;
  gap:1rem;
  border-radius: 1rem;
  transition: 0.4s;
  width: 350px;
  min-width: 200px;
}

.category>:nth-child(1){
  width: 3rem;
  height: 3rem;
  fill: white;
  fill: var(--blue);
  margin:auto auto;
}

.category>:nth-child(2){
  font-size: 1rem;
  font-weight: bold;
  margin:auto auto;
  text-align: center;
}

.category>:nth-child(3){
   font-size: 0.9rem;
   line-height: 25px;
   text-align: center;
 }
.read-more{
  display: flex;
  align-items: center;
  gap:2rem;
  margin:auto auto;

}
.read-more>img{
  width : 2rem;
}
.category:hover{
  background: rgb(63, 63, 63);
  scale:1.05;
  cursor: pointer;
}




/********************************************************************/
.moving-line {
  width: 100%;
  overflow: hidden;
  margin: auto auto; 
}

.image-container {
  display: flex;
  animation: slide 60s linear infinite;
  gap:1rem;
}

.moving-image {
  width: auto;
  height: 250px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 1rem;
  margin: 1rem 1rem;
}

@keyframes slide {
  0% {
    transform: translateX(280%);
  }
  50% {
      transform: translateX(-10%);
    }
  100% {
    transform: translateX(280%);
  }
}


/********************************************************************/
@media screen and (max-width: 900px) {
  .program-categories{
      flex-direction: column;
  }
  .category{
      margin: auto auto;
  }
}

@media screen and (max-width: 500px) {

  .category{
      width: 250px;;
  }
  .moving-image {
    height: 200px;
  }
  @keyframes slide {
    0% {
      transform: translateX(1080%);
    }
    50% {
        transform: translateX(-10%);
      }
    100% {
      transform: translateX(1080%);
    }
  }
}