
.footer {
    background-image: 
    linear-gradient(270deg,  rgba(2,0,36,0.9)0%, rgba(0, 0, 0,0.9) 100%);
    color: #ffffff;
    padding: 40px 0;
    padding-bottom: 10px;
}
  
.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
  
.footer-column {
    color:#fff;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    animation: fadeInUp 0.5s ease;
    transition: 0.4s;
    gap:0.5rem;
    text-decoration: none;
}
  
.footer-column:not(:last-child) .vertical-line {
    width: 1px;
    height: 16px;
    margin: 0 10px;
    background-color: #ffffff;
}
  
.footer-icon {
    font-size: 24px;
    margin-right: 10px;
}
  
.footer-text {
    font-size: 16px;
}
.footer-column:hover {
    scale: 1.1;
    color: var(--blue);
    cursor: pointer;
}
  
  .footer-icon a {
    margin-right: 10px;
    color: #ffffff;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .footer-icon a:hover {
    color: #aaaaaa;
  }
  
  .reserved-text {
    font-size: 14px;
    margin-left: 10px;
  }
  .footer-rights{
    text-align: center;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-column {
      margin-right: 20px;
    }
  
    .footer-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  
    .footer-text {
      font-size: 14px;
    }
  
    .footer-icon a {
      font-size: 20px;
    }
  }